import {
    Accordion,
    AccordionControl,
    AccordionPanel,
    Button,
    Checkbox,
    Loader,
    NumberInput,
    Switch,
    Table,
    TextInput
} from "@mantine/core";
import {Assessment, HomeAggregate, RecData, RecommendationAggregate, RecommendationIncentive} from "@seeair/schemas";
import {NewRecommendationInputPanel} from './NewRecommendationInputPanel.js';
import {
    Badge,
    DesignedButton,
    formatMoney,
    HStack,
    LineItemTable,
    ProjectIcon,
    QuotesTable,
    Text2Xl,
    TextBase,
    TextLg,
    TextLink,
    VStack
} from "@seeair/shared-components";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";
import {IconCancel, IconDeviceFloppy, IconPencil, IconTrash} from "@tabler/icons-react";
import {useState} from "react";

export function AdminRecommendationsPanel({home, assessment}: {
    home: HomeAggregate,
    assessment: Assessment
}) {
    const utils = trpc.useUtils();

    const {
        isPending: isAutomatedAssessmentRunning,
        isError: isRunAssessmentError,
        error: runAssessmentError,
        mutate: runAutomatedAssessment
    } = trpc.ADMIN.runAutomatedAssessment.useMutation({
        onSuccess: async () => {
            await utils.ADMIN.invalidate();
            notifications.show({message: 'Success!'})
        },
        onError: (e) =>
            notifications.show({message: `Failure: ${e.message}`})
    });
    const thisRecs = home.recommendations.filter(r => r.assessment_id == assessment.assessment_id)
    const floatingRecs = home.recommendations.filter(r => !r.assessment_id)
    return (
        <VStack>
            <RecAccordion recs={thisRecs}/>
            <div>
                <DesignedButton onClick={() => runAutomatedAssessment({
                    home_id: home.home_id,
                    assessment_id: assessment.assessment_id
                })}
                                disabled={isAutomatedAssessmentRunning}>
                    {isAutomatedAssessmentRunning ? 'Processing...' : 'Run Automated Assessment'}
                </DesignedButton>
            </div>
            <div>
                <NewRecommendationInputPanel assessment_id={assessment.assessment_id} home_id={home.home_id}/>
            </div>
            {floatingRecs.length > 0 && <RecAccordion recs={floatingRecs}/>}

        </VStack>
    )
}

export function RecAccordion({recs}: { recs: Array<RecommendationAggregate> }) {
    return <Accordion>
        {recs.map((r, i) => <Accordion.Item key={i} value={`${i}`}>
            <AccordionControl><RecControl rec={r}/></AccordionControl>
            <AccordionPanel><RecPanel rec={r}/></AccordionPanel>
        </Accordion.Item>)}
    </Accordion>
}

function RecControl({rec}: { rec: RecommendationAggregate }) {
    return <HStack leftCenter>
        <ProjectIcon lg imgSrc={`/recommendation-icons/${rec.original_rec_id}.png`}/>
        <Text2Xl medium>{rec.original_rec_id}</Text2Xl>
        <TextLg classNames="ml-4">{rec.title}</TextLg>
        <Badge classNames="ml-4">{rec.status}</Badge>
    </HStack>
}

function RecPanel({rec}: { rec: RecommendationAggregate }) {
    const utils = trpc.useUtils()
    const {
        isPending: isDeleteRecPending,
        isError: isDeleteRecError,
        error: deleteRecError,
        mutate: deleteRec
    } = trpc.ADMIN.deleteRecommendation.useMutation({
        onSuccess: async () => {
            await utils.ADMIN.invalidate()
        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    const {
        isPending: isSetRecHiddenPending,
        isError: isSetRecHiddenError,
        error: setRecHiddenError,
        mutate: setRecHidden
    } = trpc.ADMIN.setRecommendationsHidden.useMutation({
        onSuccess: async () => {
            await utils.ADMIN.invalidate()
        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <VStack classNames="first:border-t-0 border-t-2 border-black py-4"
                   key={rec.recommendation_id}>
        <HStack leftCenter>
            <TextLg classNames="mr-4">Hubspot Deal</TextLg>
            {
                rec.hubspot_deal_id
                    ? <a target="_blank"
                         href={`https://app.hubspot.com/contacts/46365478/record/0-3/${rec.hubspot_deal_id}`}><TextLink>{rec.hubspot_deal_id}</TextLink></a>
                    : <TextBase>None</TextBase>
            }
        </HStack>
        <TextLg medium>Financials</TextLg>
        <RecommendationFinancialsTable rec={rec}/>
        <TextLg medium>Line Items</TextLg>
        <LineItemTable rec={rec}/>
        <TextLg medium>Quotes</TextLg>
        <QuotesTable rec={rec}/>
        <HStack between>
            <Switch
                className="my-4"
                size="xl"
                defaultChecked={!rec.hidden || false}
                onChange={(e) => {
                    setRecHidden({
                        home_id: rec.home_id,
                        recommendation_id: rec.recommendation_id,
                        hidden: !e.currentTarget.checked
                    })
                }}
                onLabel="Visible"
                offLabel="Hidden"
                label="Visiblity"
                disabled={isSetRecHiddenPending || isSetRecHiddenError}
            />
            <Button
                size="xl"
                onClick={() => {
                    deleteRec({
                        recommendation_id: rec.recommendation_id
                    })
                }}
                disabled={isDeleteRecPending}
            >
                {
                    isDeleteRecPending ? <Loader/> : <IconTrash size="1rem"/>
                }

            </Button>
        </HStack>

    </VStack>
}

function RecommendationFinancialsTable({rec}: { rec: RecommendationAggregate }) {
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.rec_data)
    const util = trpc.useUtils()
    const {mutate: saveRecData, isPending: saveRecDataIsPending} = trpc.ADMIN.setRecommendationData.useMutation({
        onSuccess: async () => {
            notifications.show({message: "Success!"})
            setEditing(false)
            await util.ADMIN.invalidate()
        },
        onError: (error) => {
            notifications.show({message: `Error: ${error.message}`})
        }
    })
    return <VStack>
        <Table
            striped
            withTableBorder
            captionSide="bottom"
        >
            <Table.Thead>
                <Table.Th/>
                <Table.Th>High</Table.Th>
                <Table.Th>Low</Table.Th>
            </Table.Thead>
            <Table.Tbody>
                <Table.Tr>
                    <Table.Td>Upfront Cost</Table.Td>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Upfront_Cost_High'}/>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Upfront_Cost_Low'}/>
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>Net Cost</Table.Td>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Net_Cost_High'}/>
                    <FinancialInputField setData={setData} data={data} editing={editing} propKey={'Net_Cost_Low'}/>
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>Annual Savings Cost</Table.Td>
                    <FinancialInputField setData={setData} data={data} editing={editing}
                                         propKey={'Annual_Savings_High'}/>
                    <FinancialInputField setData={setData} data={data} editing={editing}
                                         propKey={'Annual_Savings_Low'}/>
                </Table.Tr>
            </Table.Tbody>
        </Table>
        <TextBase>Incentives</TextBase>
        <Table>
            <Table.Thead>
                <Table.Th>Title</Table.Th>
                <Table.Th>Time Of Purchase</Table.Th>
                <Table.Th>Amount Low</Table.Th>
                <Table.Th>Amount High</Table.Th>
                <Table.Th>URL</Table.Th>
                <Table.Th>Description</Table.Th>
                {<Table.Th></Table.Th>}
            </Table.Thead>
            <Table.Tbody>
                {
                    (data.incentives ?? []).map(
                        ((recIncentive, i) =>
                            <IncentiveInputRow setData={setData} data={data} editing={editing} row={i} key={i}/>))
                }
            </Table.Tbody>
        </Table>
        {editing && <HStack><DesignedButton onClick={() => setData({
            ...data,
            incentives: [
                ...(data.incentives ?? []),
                {
                    title: "",
                    timeOfPurchase: true,
                    amountLow: 0,
                    amountHigh: 0
                }
            ]
        })}>+ Incentive Row</DesignedButton></HStack>}
        <HStack>
            <DesignedButton disabled={saveRecDataIsPending || !editing} onClick={() => saveRecData({
                home_id: rec.home_id,
                recommendation_id: rec.recommendation_id,
                rec_data: data
            })}>
                {
                    saveRecDataIsPending
                        ? <Loader/>
                        : <IconDeviceFloppy/>
                }
            </DesignedButton>
            <DesignedButton onClick={() => {
                if (editing) {
                    setData(rec.rec_data)
                }
                setEditing(!editing)
            }}>
                {
                    editing
                        ? <IconCancel/>
                        : <IconPencil/>
                }
            </DesignedButton>
        </HStack>
    </VStack>

}

function IncentiveInputRow({editing, data, setData, row}: {
    editing: boolean,
    data: RecData,
    setData: (data: RecData) => void,
    row: number
}) {
    const rowData = (data.incentives ?? [])[row] ?? {
        timeOfPurchase: true,
        title: "",
        amountLow: 0,
        amountHigh: 0,
        url: "",
        description: ""
    }
    const changeRowData = (k: keyof RecommendationIncentive, v: any) => {
        const newIncentiveRow = {...rowData, [k]: v}
        const incentives = [...(data.incentives ?? [])]
        incentives.splice(row, 1, newIncentiveRow)
        setData({
            ...data,
            incentives
        })
    }
    return <Table.Tr>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.title}
                onChange={(event) => changeRowData('title', event.currentTarget.value)}
            />
        </Table.Td>
        <Table.Td>
            <Checkbox
                disabled={!editing}
                checked={rowData.timeOfPurchase}
                onChange={(event) => changeRowData('timeOfPurchase', event.currentTarget.checked)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.amountLow}
                onChange={(value) => changeRowData('amountLow', value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.amountHigh}
                onChange={(value) => changeRowData('amountHigh', value)}
            />
        </Table.Td>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.url}
                onChange={(event) => changeRowData('url', event.currentTarget.value)}
            />
        </Table.Td>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.description}
                onChange={(event) => changeRowData('description', event.currentTarget.value)}
            />
        </Table.Td>
    </Table.Tr>
}

function FinancialInputField({editing, data, setData, propKey}: {
    editing: boolean,
    data: RecData,
    setData: (data: RecData) => void,
    propKey: keyof Omit<Omit<RecData, 'Prerequisites'>, 'incentives'>
}) {
    // console.log(`input row ${propKey} value: ${data[propKey]}`)
    return <Table.Td>
        {
            editing
                ? <NumberInput
                    prefix="$"
                    max={Number.MAX_SAFE_INTEGER}
                    min={0}
                    decimalScale={2}
                    fixedDecimalScale
                    value={data[propKey]}
                    onChange={(value) => {
                        const newData = {...data, [propKey]: parseFloat(value as string)}
                        // console.log(`new data ${propKey} ${value}: ${JSON.stringify(newData)}`)
                        setData(newData)
                    }}/>
                : formatMoney(data[propKey] ?? 0)
        }
    </Table.Td>
}