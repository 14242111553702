import {Assessment, Home, SeeAirUser} from "@seeair/schemas";
import dayjs from "dayjs";
import {VStack, HStack, HSpacer, TextBase, TextSm} from "@seeair/shared-components";
import {IconChevronRight} from "@tabler/icons-react";
import {Link} from "@tanstack/react-router";

export function AssessmentSummaryRow({assessment,home,user}: { assessment: Assessment,home:Home,user?:SeeAirUser }) {
    if(!user) {
        return "User unknown"
    }
    return <Link
        preload={false}
        to="/admin/user/$user_id/home/$home_id/assessment/$assessment_id"
        search={{tab:'home_details'}}
        params={{assessment_id: assessment.assessment_id,user_id:user.id,home_id:home.home_id}}>
        <HStack>
            <VStack>
                <TextBase>{assessment.assessment_label}</TextBase>
            </VStack>
            <VStack>
                <TextBase>{assessment.assessment_status}</TextBase>
                <TextSm>
                    Last Ran:{' '}
                    {!assessment.last_analysis_run_at
                        ? 'N/A'
                        : dayjs(assessment.last_analysis_run_at).fromNow()}
                </TextSm>
            </VStack>
            <HSpacer />
            <IconChevronRight size="4rem"/>
        </HStack>
    </Link>
}