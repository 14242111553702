import {getLatestAssessment, HomeDetailsSchema, requiresGasInput, UserAggregate} from "@seeair/schemas";
import {Table} from "@mantine/core";
import {buildShortAddress} from "~/lib-client";
import {DesignedButton} from "@seeair/shared-components";
import {useNavigate} from "@tanstack/react-router";
import React from "react";
import dayjs from "dayjs";

export function AdminRecentPage({users}: { users: Array<UserAggregate> }) {
    const navigate = useNavigate()
    return <Table striped highlightOnHover withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Email</Table.Th>
                <Table.Th>Address</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Home Details<br/>(of {Object.keys(HomeDetailsSchema.shape).length})</Table.Th>
                <Table.Th>Electric<br/>(Months)</Table.Th>
                <Table.Th>Gas<br/>(Months)</Table.Th>
                <Table.Th>Files</Table.Th>
                <Table.Th>Recs<br/>| Hidden</Table.Th>
                <Table.Th>Quotes<br/>| LineItems</Table.Th>
                <Table.Th>Modified</Table.Th>
            </Table.Tr>
        </Table.Thead>
        {users.flatMap(u => u.homes).map(h => {
            const user = users.find(u => u.homes.find(uh => uh.home_id == h.home_id))
            const latestAssessment = getLatestAssessment(h.assessments)

            return <Table.Tr key={h.home_id}>
                <Table.Td>{user?.email}</Table.Td>
                <Table.Td>{buildShortAddress(h.address)}</Table.Td>
                <Table.Td>{latestAssessment?.assessment_status}</Table.Td>
                <Table.Td>{Object.values(latestAssessment?.home_details || {}).filter(o => o).length}</Table.Td>
                <Table.Td>{Object.values(latestAssessment?.electric_usage || {}).length}</Table.Td>
                <Table.Td>{requiresGasInput(latestAssessment?.home_details || {}) ? Object.values(latestAssessment?.electric_usage || {}).length : "N/A"}</Table.Td>
                <Table.Td>{Object.keys(latestAssessment?.assessment_files || {}).length}</Table.Td>
                <Table.Td>{h.recommendations.filter(r => !r.hidden).length} | {h.recommendations.filter(r => r.hidden).length}</Table.Td>
                <Table.Td>{h.quotes.length} | {h.quotes.flatMap(q => q.hubspot_line_items).length}</Table.Td>
                <Table.Td>{dayjs(h.aggregate_last_modified).format()}</Table.Td>
                <Table.Td>
                    <DesignedButton onClick={() =>
                        navigate({
                            to: "/admin/user/$user_id/home/$home_id/assessment/$assessment_id",
                            search: {tab: 'home_details'},
                            params: {
                                home_id: h.home_id,
                                user_id: user!.id,
                                assessment_id: latestAssessment!.assessment_id
                            }
                        })}>Admin</DesignedButton>
                </Table.Td>
                <Table.Td>
                    <DesignedButton onClick={() =>
                        navigate({
                            to: "/homes/$home_id",
                            params: {
                                home_id: h.home_id
                            }
                        })}>Homeowner</DesignedButton>
                </Table.Td>
            </Table.Tr>
        })
        }
    </Table>
}