import {createFileRoute} from "@tanstack/react-router";
import {AdminOnlyLoad, trpc} from "~/lib-client";
import {AdminAssessmentPage} from "@seeair/admin-components";
import {TextError} from "@seeair/shared-components";
import {z} from "zod";

export const AdminTabSchema = z.enum(['home_details','assessment_files','utilities','recommendations','notes'])
export type AdminTab = z.infer<typeof AdminTabSchema>
const AdminAssessmentPageSearchSchema = z.object({tab:AdminTabSchema})
type AdminAssessmentPageSearch = z.infer<typeof AdminAssessmentPageSearchSchema>
export const Route = createFileRoute('/admin/_admin/user/$user_id/home/$home_id/assessment/$assessment_id')({
    component: function AdminAssessmentPageComponent() {
        const {user_id, home_id, assessment_id} = Route.useParams()
        const {tab} = Route.useSearch()
        const [homes] = trpc.ADMIN.getHomesAggregateForUser.useSuspenseQuery({user_id})
        const home = homes.find(h => h.home_id == home_id)
        const assessment = home?.assessments?.find(a => a.assessment_id == assessment_id)
        if (!home || !assessment) {
            return <TextError>Assessment Not Found</TextError>
        }
        return <AdminAssessmentPage
            home={home}
            assessment={assessment}
            tab={tab}
        />
    },
    validateSearch: (data)=>{
        console.log(`validate search ${JSON.stringify(data)}`)
        if(data == null || Object.keys(data).length == 0){
            return {tab: 'home_details'} as AdminAssessmentPageSearch
        }
        return AdminAssessmentPageSearchSchema.parse(data)
    },
    // validateSearch: z.object({tab: AdminTabSchema}).optional(),
    wrapInSuspense: true,
    beforeLoad: AdminOnlyLoad
})


