import {trpc} from "~/lib-client";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AbsoluteCenterStack, HStack, TextError, TextLg, VStack} from './DesignBase.js';
import {Badge, Loader} from "@mantine/core";
import classNames from "classnames";
import {IconEye, IconEyeClosed} from "@tabler/icons-react";
import {initViewer, loadDocument, loadSeeAirRecs, SeeAirViewerNode} from './AutodeskHelper.js';
import {SheetsDataContext} from './SheetsDataContext.js';

type ToggleFn = ({id,recNum}:{id: number,recNum?:undefined}|{recNum: number,id?:undefined}) => void
type ToggleState = 'hidden'|'visible'|'default'
type CheckedFn = ({id,recNum}:{id: number,recNum?:undefined}|{recNum: number,id?:undefined}) => ToggleState
type OnRecToggleChangeFn = (selectedRecNums:Array<number>)=>void
export function Viewer({urn,onRecToggleChange,className}: { urn: string, onRecToggleChange:OnRecToggleChangeFn,className:string }) {
    // const viewerScriptState = useExternalScript('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js')
    const viewerScriptState = 'ready'
    const {data: viewerConfig, error: viewerConfigError, isPending: viewerConfigPending} = trpc.HOMEOWNER.get3dViewerConfig.useQuery()
    const {data: tokenResponse, error: tokenError, isPending: tokenPending} = trpc.HOMEOWNER.get3dViewerToken.useQuery()
    const [viewerError, setViewerError] = useState("")
    const forgeViewerId = useRef(null)
    const [recModelNodes, setRecRecModelNodes] = useState([] as Array<SeeAirViewerNode>)
    const [buildingModelNodes, setBuildingRecModelNodes] = useState([] as Array<SeeAirViewerNode>)
    const [model, setModel] = useState(undefined as Autodesk.Viewing.Model | undefined)
    const [viewer, setViewer] = useState(undefined as Autodesk.Viewing.GuiViewer3D | undefined)
    const [checkedItems, setCheckedItems] = useState([] as Array<number>)
    const isChecked:CheckedFn = ({id,recNum})=>{
        if(checkedItems.length == 0) {
            return 'default'
        }
        if(id) {
            if(checkedItems.includes(id)){
                return 'visible'
            }
        } else {
            const recIds = recModelNodes.filter(o=>o.recNum==recNum).map(o=>o.id)
            if(checkedItems.some(o=>recIds.includes(o))) {
                return 'visible'
            }
        }
        return 'hidden'
    }
    const toggle:ToggleFn = ({id,recNum}) => {
        let newList: Array<number>
        if(id) {
            if (checkedItems.includes(id)) {
                newList = checkedItems.filter(o => o != id)
            } else {
                newList = [...checkedItems, id]
            }
        } else {
            const recIds = recModelNodes.filter(o=>o.recNum==recNum).map(o=>o.id)
            if(checkedItems.some(o=>recIds.includes(o))) {
                //if any of this rec are selected, then we'll unselect
                newList = checkedItems.filter(o=>!recIds.includes(o))
            } else {
                //if no recs are selected, then we'll add all them
                newList = [...checkedItems, ...recIds]
            }
        }
        if (model) {
            if (newList.length > 0) {
                model.visibilityManager.isolateMultiple(newList)
                viewer!.fitToView(newList)
            } else {
                model.visibilityManager.setAllVisibility(true)
                viewer!.navigation.setRequestHomeView(true)
            }
        }
        onRecToggleChange(Array.from(new Set(recModelNodes.filter(o=>newList.includes(o.id)).map(o=>o.recNum))))
        setCheckedItems(newList)
    }
    useEffect(() => {
        if (viewerScriptState == 'ready' && viewerConfig &&tokenResponse) {
            // console.log(`initializing forge viewer with token: ${tokenResponse.token}`)
            // console.log(`initializing forge viewer with urn: ${JSON.stringify(urnResponse)}`)
            const options: Autodesk.Viewing.InitializerOptions = {
                env: 'AutodeskProduction',//'AutodeskProduction2',
                api: 'derivativeV2',//'streamingV2',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
                disableDefaultUI: true,
                getAccessToken: function (onTokenReady: (s: string, t: number) => void) {
                    const timeInSeconds = 3600; // Use value provided by APS Authentication (OAuth) API
                    onTokenReady(tokenResponse.token, timeInSeconds);
                }
            };
            //void here to ignore the hanging promise
            void (async () => {
                const viewer = await initViewer(options,forgeViewerId.current!,{})
                const model = await loadDocument(viewer,urn)
                setModel(model)
                setViewer(viewer)
                if(viewerConfig.hideToolbar) {
                    viewer.toolbar.setVisible(false)
                }
                const nodes = await loadSeeAirRecs(model)
                setRecRecModelNodes(nodes.filter(({recNum}) =>
                    recNum > 0))
                setBuildingRecModelNodes(nodes.filter(({name}) =>
                    viewerConfig.buildingNodeNames.includes(name)))
            })();
        }
    }, [viewerScriptState, tokenResponse, urn, forgeViewerId,viewerConfig])
    if (tokenPending) {
        return <AbsoluteCenterStack><Loader/></AbsoluteCenterStack>
    }
    if (tokenError || viewerError.length > 0) {
        return <AbsoluteCenterStack><VStack>
            <TextError>{tokenError?.message}</TextError>
            <TextError>{viewerError}</TextError>
        </VStack></AbsoluteCenterStack>
    }
    switch (viewerScriptState) {
        case "ready":
            return <div className={classNames("relative",className)}>
                    <div ref={forgeViewerId}/>
                    <ToggleOverlay
                        buildingModelNodes={buildingModelNodes}
                        recModelNodes={recModelNodes}
                        toggle={toggle}
                        isChecked={isChecked}
                    />
                </div>
    }
}

export function ToggleOverlay({buildingModelNodes, toggle, isChecked, recModelNodes}: {
    buildingModelNodes: Array<{id:number,name:string}>,
    recModelNodes: Array<SeeAirViewerNode>,
    toggle: ToggleFn,
    isChecked: CheckedFn
}) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const uniqueRecs = new Set(recModelNodes.map(o=>o.recNum))
    const recs:Array<{recNum:number,name:string}>=Array.from(uniqueRecs).map(o=>({
        recNum:o,
        name:recommendationsSheet.find(r=>parseInt(r.id)==o)?.title ?? `SeeAir Recommendation ${o}`
    }))
    return <div className="absolute bottom-4 left-4 z-50">
        <VStack>
            {buildingModelNodes.length>0&&<TextLg m0>Building Layers</TextLg>}
            <HStack wrap>
                {buildingModelNodes.map(item => <LayerToggle
                    checked={isChecked({id:item.id})}
                    title={item.name}
                    toggle={() => toggle({id:item.id})}
                    key={item.id}/>)}
            </HStack>
            {recModelNodes.length>0&&<TextLg m0>Project Layers</TextLg>}
            <HStack wrap>
                {recs.map(item => <LayerToggle
                    checked={isChecked({recNum:item.recNum})}
                    title={item.name}
                    toggle={() => toggle({recNum:item.recNum})}
                    key={item.recNum}/>)}
            </HStack>
        </VStack>
    </div>
}



function LayerToggle({checked, toggle, title}: {
    checked: ToggleState,
    toggle: () => void,
    title: string
}) {
    return <Badge className={classNames("m-1 p-2 cursor-pointer",{
        "bg-gray-400":checked != 'visible',
        "bg-primary-light-blue":checked == 'visible'
    })} onClick={toggle}>
        <HStack center>
            {title}
            {
                checked == 'hidden'
                    ? <IconEyeClosed size="1rem" className="ml-2" />
                    : <IconEye size="1rem" className="ml-2"/>
            }
        </HStack>
    </Badge>
}
