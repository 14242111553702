import {Button, FileInput, InputError, InputLabel, Loader, Table,} from '@mantine/core'
import {notifications} from '@mantine/notifications'
import dayjs from 'dayjs'
import React, {ReactNode, useState} from 'react'
import {trpc} from '~/lib-client'
import type {Assessment, EnergyDataRecord, HomeAggregate} from '@seeair/schemas'
import {parse as parseCsv} from 'csv-parse/browser/esm/sync'
import {captureException, getCurrentScope} from '@sentry/react'
import {Box, HDivider, HStack, RStack, Text2Xl, TextBase, TextLg, VStack} from "./DesignBase.js";


async function parseUtilityData(file: File | null): Promise<Array<EnergyDataRecord> | undefined> {
    let _fileText
    if (!file || !(_fileText = await file.text())) return undefined

    let rows
    try {
        rows = parseCsv(_fileText, {relax_column_count: true}) as Array<Array<string>>
    } catch (err) {
        throw new Error(`Failed to parse file: ${file.name}`)
    }

    const greenHeaderIndex = rows.findIndex((row) => row.includes('Usage per day'))
    const natlElectricHeaderIndex = rows.findIndex((row) => row.includes('USAGE (kWh)'))
    const natlGasHeaderIndex = rows.findIndex((row) => row.includes('USAGE (therms)'))

    const toNum = (inp: string | undefined) => Number(inp?.replace(/[,$]/g, '') ?? '')

    if (greenHeaderIndex !== -1) {
        const headers = rows[greenHeaderIndex]!
        const data = rows.slice(greenHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('Read Date')]).toISOString(),
            usage: toNum(row[headers.indexOf('Usage')]),
            cost: toNum(row[headers.indexOf('Charge')]),
        }))
    } else if (natlElectricHeaderIndex !== -1) {
        const headers = rows[natlElectricHeaderIndex]!
        const data = rows.slice(natlElectricHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('END DATE')]).toISOString(),
            usage: toNum(row[headers.indexOf('USAGE (kWh)')]),
            cost: toNum(row[headers.indexOf('COST')]),
        }))
    } else if (natlGasHeaderIndex !== -1) {
        const headers = rows[natlGasHeaderIndex]!
        const data = rows.slice(natlGasHeaderIndex + 1)

        return data.map((row) => ({
            date: dayjs(row[headers.indexOf('END DATE')]).toISOString(),
            usage: toNum(row[headers.indexOf('USAGE (therms)')]),
            cost: toNum(row[headers.indexOf('COST')]),
        }))
    } else {
        throw new Error('No data found. Make sure you are uploading a supported format')
    }
}

export function EditUtilitiesPanel({home, assessment}: {
    home: HomeAggregate
    assessment: Assessment
}) {
    const [electric, setElectric] = useState<File | null>(null)
    const [gas, setGas] = useState<File | null>(null)
    const [error, setError] = useState<ReactNode | null>(null)

    const setUtilitiesData = trpc.HOMEOWNER.setUtilitiesData.useMutation({
        onMutate: () => setError(null),
        onSuccess: () => {
            notifications.show({message: 'Success!'})
        },
    })
    const gas_usage = Object.values(assessment.gas_usage ?? {})
    const electric_usage = Object.values(assessment.electric_usage ?? {})
    return (<VStack>
            <RStack>
                <VStack>
                    <Text2Xl>Gas</Text2Xl>
                    <Table striped highlightOnHover withTableBorder>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Date</Table.Th>
                                <Table.Th>Days</Table.Th>
                                <Table.Th>Usage</Table.Th>
                                <Table.Th>Cost</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        {
                            gas_usage.map(u => <Table.Tr>
                                <Table.Td>{u.date}</Table.Td>
                                <Table.Td>{u.num_days}</Table.Td>
                                <Table.Td>{u.usage}</Table.Td>
                                <Table.Td>{u.cost}</Table.Td>
                            </Table.Tr>)
                        }
                    </Table>
                </VStack>
                <VStack>
                    <Text2Xl>Electric</Text2Xl>
                    <Table striped highlightOnHover withTableBorder>
                        <Table.Thead>
                            <Table.Tr>
                                <Table.Th>Date</Table.Th>
                                <Table.Th>Days</Table.Th>
                                <Table.Th>Usage</Table.Th>
                                <Table.Th>Cost</Table.Th>
                            </Table.Tr>
                        </Table.Thead>
                        {
                            electric_usage.map(u => <Table.Tr>
                                <Table.Td>{u.date}</Table.Td>
                                <Table.Td>{u.num_days}</Table.Td>
                                <Table.Td>{u.usage}</Table.Td>
                                <Table.Td>{u.cost}</Table.Td>
                            </Table.Tr>)
                        }
                    </Table>
                </VStack>
            </RStack>
            <Box>
                <VStack>
                    <VStack>
                        <TextLg>Upload Your Gas and Electricity Usage</TextLg>
                        <TextBase>Last 12 Months</TextBase>
                    </VStack>
                    <VStack>
                        <HStack>
                            <InputLabel>Electric</InputLabel>
                            <FileInput
                                value={electric}
                                onChange={setElectric}
                                accept=".csv"
                                placeholder="Select Electric Usage File..."
                                clearable
                            />
                        </HStack>
                        <HStack>
                            <InputLabel>Gas</InputLabel>
                            <FileInput
                                value={gas}
                                onChange={setGas}
                                accept=".csv"
                                placeholder="Select Gas Usage File..."
                                clearable
                            />
                        </HStack>
                        {setUtilitiesData.isPending ? (
                            <HStack>
                                <Loader size="2rem"/>
                            </HStack>
                        ) : (
                            <>
                                {(error || setUtilitiesData.error) && (
                                    <InputError className="whitespace-pre">
                                        {error ??
                                            setUtilitiesData.error?.message ??
                                            'Something went wrong...'}
                                    </InputError>
                                )}
                                <Button
                                    type="submit"
                                    onClick={async () => {
                                        try {
                                            setError(null)
                                            setUtilitiesData.reset()

                                            if (!gas && !electric) {
                                                return
                                            }

                                            const [electricUsageRes, gasUsageRes] = await Promise.allSettled([
                                                parseUtilityData(electric),
                                                parseUtilityData(gas),
                                            ])

                                            const isElectricErr = electricUsageRes.status === 'rejected'
                                            const electricUsage = !isElectricErr
                                                ? electricUsageRes.value
                                                : undefined

                                            const isGasErr = gasUsageRes.status === 'rejected'
                                            const gasUsage = !isGasErr ? gasUsageRes.value : undefined

                                            if (isElectricErr || isGasErr) {
                                                throw new Error(
                                                    [
                                                        `Error while processing files`,
                                                        isGasErr && (gasUsageRes.reason as Error)?.message,
                                                        isElectricErr && (electricUsageRes.reason as Error)?.message,
                                                    ]
                                                        .filter(Boolean)
                                                        .join('\n')
                                                )
                                            }

                                            const minEntries = 3
                                            if (electricUsage && electricUsage.length < minEntries) {
                                                throw new Error(
                                                    `Electric data must have at least ${minEntries} entries`
                                                )
                                            }
                                            if (gasUsage && gasUsage.length < minEntries) {
                                                throw new Error(
                                                    `Gas data must have at least ${minEntries} entries`
                                                )
                                            }

                                            setUtilitiesData.mutate({
                                                home_id: assessment.home_id,
                                                assessment_id: assessment.assessment_id,
                                                electricUsage,
                                                gasUsage,
                                            })
                                        } catch (err) {
                                            const scope = getCurrentScope()
                                            if (gas) {
                                                scope.addAttachment({filename: gas.name, data: await gas.text()})
                                            }
                                            if (electric) {
                                                scope.addAttachment({
                                                    filename: electric.name,
                                                    data: await electric.text(),
                                                })
                                            }
                                            captureException(err)
                                            scope.clearAttachments()

                                            setError((err as Error)?.message ?? 'Something went wrong...')
                                        }
                                    }}
                                >
                                    Upload Files
                                </Button>
                            </>
                        )}
                    </VStack>
                </VStack>
            </Box>
            <Box>
                <TextLg>How to Download Your Data</TextLg>
                <TextBase>
                    Look for the Green Button in your account:
                    <img src="/design-assets/download_green.png" alt=""/>
                </TextBase>
                <HDivider/>
                <HStack>
                    <Button
                        onClick={() => window.open("https://www.eversource.com/content/residential/save-money-energy/energy-savings-tips-tools/green-button", "_blank")}
                    >
                        <img src="/design-assets/download_eversource.png" alt=""/>
                    </Button>
                    <Button
                        onClick={() => window.open("https://myaccount.nationalgrid.com/s/account-overview", "_blank")}>
                        <img src="/design-assets/download_national.png" alt=""/>
                    </Button>
                </HStack>
            </Box>
        </VStack>
    )
}
