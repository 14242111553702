import {
    Box,
    RStack,
    HomeownerInputCheck,
    VStack,
    HStack,
    TextXl,
    DesignedButton, COLOR_LIGHT_GREY, COLOR_DARK_GREY
} from "@seeair/shared-components";
import {Assessment, Home, KnownHomeDetailsKeys, validateHomeownerInputs} from "@seeair/schemas";

const NumberFormatter = Intl.NumberFormat('en-US', {})

function ValueWithLabel({value, label}: { value: any, label: string }) {

    let display = value
    if (!isNaN(Number(display))) {
        display = NumberFormatter.format(Number(display))
    }

    return (
        <div className="flex flex-col justify-between items-center w-40 h-20  p-2 m-2 rounded-2xl"
             style={{
                 background: COLOR_LIGHT_GREY,
             }}>
            <span className="text-base text-center m-0 max-h-16"
                  style={{
                      overflow: 'hidden'
                  }}>{display}</span>
            <span className="text-xs m-0" style={{color:COLOR_DARK_GREY}}>{label}</span>
        </div>
    )
}

export function HomeDetailsGridBox({home, latestAssessment, openDetailsModal}: {
    home: Home,
    latestAssessment?: Assessment,
    openDetailsModal: () => void
}) {
    return <Box>
        <RStack lg center>
            <img
                className="object-cover w-96 h-96 lg:mr-20"
                alt=""
                src={home.thumbnail_url || undefined}
            />
            <VStack>
                <HStack center>
                    <TextXl center>{home.full_address_id}</TextXl>
                </HStack>
                <HStack  center>
                    <ValueWithLabel
                        value={latestAssessment?.home_details?.[KnownHomeDetailsKeys.Beds] || '-'}
                        label="beds"
                    />
                    <ValueWithLabel
                        value={latestAssessment?.home_details?.[KnownHomeDetailsKeys.Baths] || '-'}
                        label="baths"
                    />
                    <ValueWithLabel
                        value={latestAssessment?.home_details?.[KnownHomeDetailsKeys.Sqft] || '-'}
                        label="sqft"
                    />
                </HStack>
                <HStack  center>
                    <ValueWithLabel
                        value={latestAssessment?.home_details?.floors || '-'}
                        label="floors"
                    />
                    <ValueWithLabel
                        value={`${latestAssessment?.home_details?.primary_heating} ${latestAssessment?.home_details?.secondary_heating}` || '-'}
                        label="heating"
                    />
                    <ValueWithLabel
                        value={`${latestAssessment?.home_details?.primary_cooling} ${latestAssessment?.home_details?.secondary_cooling}` || '-'} // Need to check whether the rest of these function properly
                        label="cooling"
                    />
                </HStack>
                <HStack baseline style={{marginTop:"2rem"}}>
                    {latestAssessment&&<HomeownerInputCheck validationResponse={validateHomeownerInputs(latestAssessment)} />}
                    <DesignedButton
                        onClick={openDetailsModal}
                    >
                        Verify Home Details
                    </DesignedButton>
                </HStack>
            </VStack>
        </RStack>
    </Box>
}