import dayjs from 'dayjs'
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts'
import {TextSm} from "./DesignBase.js";
import {ElectrificationData} from "@seeair/schemas";

interface RenderConfig {
    mobile?: boolean
    width: number
    height: number
    energy_chart: ElectrificationData['energy_chart']
}

export function EnergyChart({energy_chart, mobile, height, width}: RenderConfig) {
    const data = energy_chart
        .sort((a, b) => {
            return dayjs(a.Date).unix() - dayjs(b.Date).unix()
        })
        .slice(0, 12)
        .map((row) => ({
            name: dayjs(row.Date).format('MMM'),
            Baseload: row.Baseload_Usage,
            Heating: row.Heating_Usage,
            Cooling: row.Cooling_Usage,
        }))

    return (
        <ResponsiveContainer width={'100%'} height={350}>
            <BarChart
                data={data}
                stackOffset="sign"
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="50000" vertical={false}/>
                <XAxis dataKey="name" includeHidden/>
                <YAxis padding={{bottom: 1}}/>
                <Tooltip/>
                <Legend
                    layout={mobile ? "horizontal" : "vertical"}
                    align={mobile ? "center" : "right"}
                    verticalAlign={mobile ? "top" : "middle"}
                    wrapperStyle={{paddingLeft: 10}}
                />
                {/* <ReferenceLine y={0} stroke="#000" /> */}
                <Bar
                    dataKey="Baseload"
                    fill="#E3DE03"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    dataKey="Heating"
                    fill="#DC9540"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    dataKey="Cooling"
                    fill="#59BBFD"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

export function ElectrificationSmallChart({data: {infiltration, insulation, radiation, excess}}: {
    data: {
        infiltration: number | null | undefined
        insulation: number | null | undefined
        radiation: number | null | undefined
        excess: number | null | undefined
    }
}) {
    if (!infiltration || !insulation || !radiation || !excess) {
        return <ResponsiveContainer width={'100%'} height={105}>
            <span>Incomplete Data</span>
        </ResponsiveContainer>
    }
    const sum = infiltration + insulation + radiation + excess

    const max = Math.ceil((sum + 1) / 10) * 10
    const tickSize = max >= 35 ? 10 : 5
    const ticks = Array.from({length: max / tickSize + 1}, (_, k) => k * tickSize)

    return (
        <ResponsiveContainer width={'100%'} height={105}>
            <BarChart
                data={[{name: 'Data', infiltration, insulation, radiation, excess}]}
                stackOffset="sign"
                layout="vertical"
                barCategoryGap={4}
            >
                <XAxis
                    type="number"
                    axisLine={false}
                    tickMargin={4}
                    domain={[0, max]}
                    ticks={ticks}
                    minTickGap={0}
                    interval={0}
                    padding={{right: 8}}
                />
                <YAxis dataKey="name" type="category" hide/>
                <Legend
                    layout="horizontal"
                    align="center"
                    verticalAlign="top"
                    wrapperStyle={{paddingBottom: '10px'}}
                    formatter={(value, entry, index) => (
                        <TextSm>
                            {value}
                        </TextSm>
                    )}
                />
                <Bar
                    stroke="#333"
                    dataKey="infiltration"
                    fill="#7AD3FF"
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    stroke="#333"
                    dataKey="insulation"
                    fill="#ECB768"
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    stroke="#333"
                    dataKey="radiation"
                    fill="#E8EB62"
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    stroke="#333"
                    dataKey="excess"
                    fill="#C7C7C7"
                    isAnimationActive={false}
                    stackId="stack"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}
