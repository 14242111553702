import {createFileRoute, redirect} from '@tanstack/react-router'
import {AuthenticatedLoad, trpc} from '~/lib-client'
import {
	AcceptInvitePage,
	AcceptInviteSchema,
	AcceptInviteType
} from "@seeair/homeowner-components";

export const Route = createFileRoute('/homes/_homes/accept-invite')({
	validateSearch: (data): AcceptInviteType | Record<string, never> => {
		const parsed = AcceptInviteSchema.parse(data)
		return parsed
	},
	async beforeLoad(opts) {
		AuthenticatedLoad(opts)
		const {hid} = opts.search
		let home
		try {
			home = await opts.context.utils.HOMEOWNER.getHomeAggregate.fetch({home_id: hid})
		} catch {}

		if (home) {
			throw redirect({
				to: '/homes/$home_id',
				params: {home_id: home.home_id},
			})
		}
	},
	component: function AcceptInvitePageRoute(){
		const {hid, code} = Route.useSearch()
		const [shareDetails] = trpc.HOMEOWNER.getShareDetails.useSuspenseQuery({hid, code})
		return <AcceptInvitePage hid={hid} code={code} shareDetails={shareDetails} />
	},
})