import {createFileRoute} from '@tanstack/react-router'
import {AuthenticatedLoad, trpc} from '~/lib-client'
import {HomeDashboard} from "@seeair/homeowner-components";

export const Route = createFileRoute('/homes/_homes/$home_id/')({
	component: function HomeDashboardRoute() {
		const {home_id} = Route.useParams()
		const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})
		return <HomeDashboard home={home}/>
	},
	wrapInSuspense: true,
	beforeLoad:AuthenticatedLoad,
	async loader({params:{home_id}, context: {utils}, preload}) {
		await utils.HOMEOWNER.getHomeAggregate.prefetch({home_id})
	},
})
