import {Assessment, Home, HomeAggregate} from "@seeair/schemas";
import {buildShortAddress, trpc} from "~/lib-client";
import React from "react";
import {AssessmentNotesPanel} from './AssessmentNotesPanel.js';
import {AdminRecommendationsPanel} from './AdminRecommendationsPanel.js';
import {
    AssessmentFilesTable,
    COLOR_WHITE,
    EditUtilitiesPanel,
    FullWithDesignedSection, HStack, RStack, Text2Xl, Text4Xl
} from "@seeair/shared-components";
import {Menu, Tabs, UnstyledButton} from "@mantine/core";
import {IconBulb, IconChecklist, IconDots, IconFiles, IconJson, IconNote} from "@tabler/icons-react";
import {AssessmentStatusWidget} from './AssessmentStatusWidget.js';
import {EditMissingHomeDetailsPanel} from "@seeair/shared-components";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import {AdminTab} from "~/routes/admin/_admin/user/$user_id/home.$home_id.assessment.$assessment_id";
import {useNavigate} from "@tanstack/react-router";
export function AdminAssessmentPage({home, assessment,tab="home_details"}: {
    home: HomeAggregate
    assessment: Assessment
    tab?:AdminTab
}) {
    const navigate = useNavigate()
    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <RStack xl>
                <HStack baseline>
                    <Text4Xl>{buildShortAddress(home.address)}</Text4Xl>
                    <Text2Xl classNames="ml-4">{assessment.assessment_label}</Text2Xl>
                </HStack>
                <AssessmentStatusWidget assessment={assessment} />
                <DeleteHomeWidget home={home} />
            </RStack>
            <Tabs defaultValue={tab} onChange={async (value)=> {
                await navigate({search:{tab:value}})
            }}>
                <Tabs.List>
                    <Tabs.Tab value="home_details" leftSection={<IconJson/>}><Text2Xl>Home Details</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="assessment_files" leftSection={<IconFiles/>}><Text2Xl>Assessment Files</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="utilities" leftSection={<IconBulb/>}><Text2Xl>Utilities</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="recommendations" leftSection={<IconChecklist/>}><Text2Xl>Recommendations</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="notes" leftSection={<IconNote/>}><Text2Xl>Notes</Text2Xl></Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="home_details">
                    <EditMissingHomeDetailsPanel
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="assessment_files">
                    <AssessmentFilesTable
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="utilities">
                    <EditUtilitiesPanel
                        home={home}
                        assessment={assessment} />
                </Tabs.Panel>
                <Tabs.Panel value="notes">
                    <AssessmentNotesPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="recommendations">
                    <AdminRecommendationsPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    )
}
export function DeleteHomeWidget({home}:{home:Home}) {
    const utils = trpc.useUtils()
    const deleteHome = trpc.ADMIN.deleteHome.useMutation({
        onSuccess: () => notifications.show({message: 'Success!'}),
        onError: (err) => notifications.show({message: err.message}),
        onSettled: () => utils.ADMIN.invalidate()
    })
    return <Menu>
        <Menu.Target>
            <UnstyledButton>
                <IconDots size="1.5rem"/>
            </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
            <Menu.Item
                onClick={() =>
                    modals.openConfirmModal({
                        title: 'Delete home',
                        children: (
                            <div>
                                Are you sure you want to delete <br/>
                                <strong>{buildShortAddress(home.address)}</strong>?
                            </div>
                        ),
                        labels: {confirm: 'Confirm', cancel: 'Cancel'},
                        onConfirm: async () => {
                            await deleteHome.mutateAsync({home_id: home.home_id})
                        },
                    })
                }
            >
                Delete Home
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}