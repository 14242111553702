import {
    Assessment,
    AssessmentFile,
    AssessmentFiles,
    AssessmentFilesEnum,
    AssessmentFilesEnumSchema,
    FileUploadNonce,
    getFileVersionsSortedByLatest,
} from "@seeair/schemas";
import React, {useEffect, useState} from "react";
import {
    Button,
    Combobox,
    FileInput,
    InputBase,
    Loader,
    Popover,
    Table,
    UnstyledButton,
    useCombobox
} from "@mantine/core";
import {trpc} from "~/lib-client";
import {IconCircleCheckFilled, IconDownload, IconExclamationCircle} from "@tabler/icons-react";
import {HStack, TextSm, TextXs, VStack} from "./DesignBase.js";
import {DesignedButton} from "./DesignComponents.js";
import classNames from "classnames";
import {lastNChars} from './Util.js';
import {notifications} from "@mantine/notifications";
import {useDisclosure} from "@mantine/hooks";

export function AssessmentFilesTable({assessment}: { assessment: Assessment }) {
    const [triggerDownloads, setTriggerDownload] = useState(false)
    const files = assessment.assessment_files || {} as AssessmentFiles
    const allPossibleFiles = Object.keys(AssessmentFilesEnumSchema.Values) as Array<AssessmentFilesEnum>
    return <VStack>
        <DesignedButton onClick={() => {
            setTriggerDownload(true)
        }}>Download All Files</DesignedButton>
        <Table striped highlightOnHover withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>File</Table.Th>
                    <Table.Th>Count</Table.Th>
                    <Table.Th className="max-w-96 block">Version</Table.Th>
                    <Table.Th>Info</Table.Th>
                    <Table.Th>Download</Table.Th>
                    <Table.Th>Upload</Table.Th>
                    {/*<Table.Th>Delete</Table.Th>*/}
                </Table.Tr>
            </Table.Thead>
            {allPossibleFiles.map((k) => <AssessmentFilesRow triggerDownload={triggerDownloads} key={k} name={k}
                                                             fileVersions={files[k]}
                                                             assessment={assessment}/>)}
        </Table>
    </VStack>
}

export function AssessmentFilesRow({triggerDownload, assessment, fileVersions, name}: {
    assessment: Assessment,
    fileVersions: Array<AssessmentFile> | undefined,
    name: keyof AssessmentFiles,
    triggerDownload: boolean
}) {
    const sortedFileVersions = getFileVersionsSortedByLatest(fileVersions ?? [])
    const [selectedFileVersion, setSelectedFileVersion] = useState(sortedFileVersions[0])
    // const {mutate} = trpc.ADMIN.deleteAssessmentFileVersion.useMutation()
    return <Table.Tr>
        <Table.Td>{name}</Table.Td>
        <Table.Td>{fileVersions?.length ?? 0}</Table.Td>
        <Table.Td className="max-w-96 block overflow-hidden">
            <VStack>
                <AssessmentFileVersionSelectWidget
                    sortedFileVersions={sortedFileVersions}
                    selectedFileVersion={selectedFileVersion}
                    setSelectedFileVersion={setSelectedFileVersion}/>
                <TextXs>{selectedFileVersion?.s3_url}</TextXs>
            </VStack>
        </Table.Td>
        <Table.Td><AssessmentFilesInfoWidget assessment_id={assessment.assessment_id} fileEnum={name}
                                             selectedFileVersion={selectedFileVersion}/></Table.Td>
        <Table.Td><AssessmentFileDownloadWidget triggerDownload={triggerDownload}
                                                selectedFileVersion={selectedFileVersion}/></Table.Td>
        <Table.Td><AssessmentFileUploadWidget assessment_id={assessment.assessment_id} fileEnum={name}/></Table.Td>
        {/*<Table.Td><DesignedButton onClick={()=>mutate({})}><IconTrash /></DesignedButton></Table.Td>*/}
    </Table.Tr>
}

const renderableFiles: Array<AssessmentFilesEnum> = ['capture_rendering_enhanced', 'capture_rendering_cleaned']

function AssessmentFilesInfoWidget({assessment_id, fileEnum, selectedFileVersion}: {
    assessment_id: string,
    fileEnum: AssessmentFilesEnum,
    selectedFileVersion?: AssessmentFile
}) {

    return <VStack>
        {selectedFileVersion && renderableFiles.includes(fileEnum) &&
            <RenderableFileInfoWidget selectedFileVersion={selectedFileVersion}/>
        }
    </VStack>
}

function RenderableFileInfoWidget({selectedFileVersion}: { selectedFileVersion: AssessmentFile }) {
    const [opened, {close, open}] = useDisclosure(false);
    const {data, error, isPending} = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({
        file: selectedFileVersion
    })
    if (isPending) {
        return <Loader/>
    }
    let icon
    let text
    if (error) {
        icon = <IconExclamationCircle className="text-red-500"/>
        text = "There was an error loading details about the APS file upload and conversion"
    } else if (data.conversionStatus == 'success') {
        return <IconCircleCheckFilled className="text-green-500"/>
    } else {
        if(data.conversionStatus == 'failed') {
            text = "The APS Conversion Failed"
            icon = <IconExclamationCircle className="text-red-500"/>
        } else {
            icon = <IconExclamationCircle className="text-yellow-500"/>
            if(data.conversionStatus == 'not_found'){
                if (data.objectExists) {
                    text = "The file was uploaded to APS, but the conversion is missing"
                } else {
                    text = "The file has not yet been uploaded to APS"
                }
            }
            text = `Status: ${data.conversionStatus}; Progress: ${data.conversionProgress}`
        }
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onMouseEnter={open} onMouseLeave={close}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                <TextSm inverse>{text}</TextSm>)
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function AssessmentFileVersionSelectWidget({sortedFileVersions, selectedFileVersion, setSelectedFileVersion}: {
    sortedFileVersions: Array<AssessmentFile>,
    selectedFileVersion?: AssessmentFile,
    setSelectedFileVersion: (f: AssessmentFile) => void
}) {

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });
    return <Combobox
        store={combobox}
        size="xl"
        disabled={!sortedFileVersions}
    >
        <Combobox.Target targetType="button">
            <InputBase
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron/>}
                rightSectionPointerEvents="none"
                onClick={() => combobox.toggleDropdown()}
            >
                {selectedFileVersion
                    ? selectedFileVersion.name
                    : null
                }
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
                {sortedFileVersions.map((f, i) => <UnstyledButton
                    className={classNames("first:border-0 first:mt-0 mt-2 border-t border-black border-solid w-full", {"bg-gray-200": f.s3_url == selectedFileVersion?.s3_url})}
                    onClick={() => {
                        setSelectedFileVersion(f)
                        combobox.toggleDropdown()
                    }}><FileComboboxItem file={f}/></UnstyledButton>)}
            </Combobox.Options>
        </Combobox.Dropdown>
    </Combobox>
}

function AssessmentFileUploadWidget({assessment_id, fileEnum}: {
    assessment_id: string,
    fileEnum: AssessmentFilesEnum
}) {
    const utils = trpc.useUtils()
    const [fileUpload, setFileUpload] = useState<File | null>(null)
    const {
        data: saveUrlResponse,
        isError: isSaveUrlError,
        error: saveUrlError,
        isPending: isSaveUrlPending,
        mutate: saveUrl
    } =
        trpc.ADMIN.saveAssessmentFileUploadUrl.useMutation({
            onSuccess: async () => {
                await utils.ADMIN.invalidate()
                setFileUpload(null)
                notifications.show({message: "Success!"})
            },
            onError: (e) => {
                notifications.show({message: `Error uploading file ${e.message}`})
            }
        })
    const {
        data: fileUploadUrl
        , isError: isUploadFileError,
        error: fileUploadError,
        isPending: isUploadFilePending,
        mutate: uploadFile
    } = trpc.ADMIN.getAssessmentFileUploadUrl.useMutation({
        onSuccess: async ({url, nonce}: { url: string, nonce: FileUploadNonce }) => {
            try {
                await fetch(url, {
                    method: "PUT",
                    body: fileUpload!,
                    headers: {
                        "Content-Type": fileUpload!.type
                    }
                })
                saveUrl(nonce)
            } catch (e) {
                console.log("upload error", e)
            }
        }
    })
    return <HStack leftCenter>
        <FileInput
            value={fileUpload}
            onChange={setFileUpload}
            placeholder={`Select File ...`}
            clearable
        />
        <Button disabled={!fileUpload || isUploadFilePending}
                onClick={() => uploadFile({
                    assessment_id: assessment_id,
                    contentType: fileUpload!.type,
                    assessmentFileEnum: fileEnum,
                    fileName: fileUpload!.name
                })}
        >{isUploadFilePending || isSaveUrlPending ? <Loader/> : "Upload"}</Button>
        {isUploadFileError && <span>{fileUploadError.message}</span>}
        {isSaveUrlError && <span>{saveUrlError.message}</span>}
    </HStack>
}

function AssessmentFileDownloadWidget({triggerDownload, selectedFileVersion}: {
    triggerDownload: boolean,
    selectedFileVersion?: AssessmentFile
}) {
    const {
        data: fileDownloadUrl,
        isError: isFileDownloadUrlError,
        isPending: isFileDownloadUrlPending,
        mutate: getDownloadUrl
    } = trpc.ADMIN.getAssessmentFileDownloadUrl.useMutation({
        onSuccess: async ({url}: { url: string }) => {
            if (triggerDownload) {
                window.open(url, "_blank")
            }
        }
    })
    useEffect(() => {
        if (triggerDownload && selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }, [triggerDownload, getDownloadUrl, selectedFileVersion]);

    return <Button disabled={!selectedFileVersion} onClick={() => {
        if (fileDownloadUrl) {
            window.open(fileDownloadUrl.url, "_blank")
        } else if (selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }}>
        {fileDownloadUrl ? <IconDownload/> : isFileDownloadUrlPending ?
            <Loader/> : "Click To Get Download Link"}
    </Button>
}

function FileComboboxItem({file}: { file: AssessmentFile }) {
    return <VStack center>
        <TextSm>{lastNChars(file.name, 20)}</TextSm>
        <TextSm>{file.created_by}</TextSm>
        <TextSm>{file.created_date}</TextSm>
    </VStack>
}