import {createFileRoute, Outlet} from '@tanstack/react-router'

import {Layout, SheetsDataProvider} from "@seeair/shared-components";

export const Route = createFileRoute('/homes/_homes')({
    component: () => <SheetsDataProvider>
        <Layout>
            <Outlet/>
        </Layout>
    </SheetsDataProvider>,
    wrapInSuspense: true
})