import {buildShortAddress, trpc} from "~/lib-client";
import {getLatestAssessment, HomeAggregate} from "@seeair/schemas";
import React, {useState} from "react";
import {
    EditHomeDetailsPanel,
    EditUtilitiesPanel,
    HStack,
    PageSection,
    ShareButton,
    Text5Xl,
    TextLg,
    VStack
} from "@seeair/shared-components";
import {HomeDetailsGridBox} from "./HomeDetailsGridBox.js";
import {SharePanel} from "./SharePanel.js";
import {Modal} from "@mantine/core";
import {RecommendationsPage} from './RecommendationsPage.js';

export function HomeDashboard({home}: { home: HomeAggregate }) {
    const latestAssessment = getLatestAssessment(home.assessments)
    const utils = trpc.useUtils()
    const [currentModal, setCurrentModal] = useState<'share' | 'details' | 'energy'>()
    return (
        <VStack>
            <HStack>
                <Text5Xl>{buildShortAddress(home.address)}</Text5Xl>
                {(home.permissions?.has('owner') ?? false) && (
                    <ShareButton onClick={() => setCurrentModal('share')}/>
                )}
            </HStack>
            <PageSection title="Home Details">
                <HomeDetailsGridBox
                    home={home}
                    latestAssessment={latestAssessment}
                    openDetailsModal={() => setCurrentModal('details')}
                />
            </PageSection>
            <PageSection title="Home Improvement Plan">
                {
                    latestAssessment?.assessment_status == 'done'
                        ? <RecommendationsPage home={home}/>
                        : <TextLg center>Plan not yet available</TextLg>
                }
            </PageSection>
            {
                latestAssessment &&
                <Modal title={"Home Details"} opened={currentModal == 'details'} onClose={() => setCurrentModal(undefined)}>
                    <EditHomeDetailsPanel
                        assessment={latestAssessment}
                        readOnly={!home.permissions || !home.permissions.has('write')}
                    />
                </Modal>
            }
            {
                latestAssessment &&
                <Modal title="Utilities" opened={currentModal == 'energy'}
                       onClose={() => setCurrentModal(undefined)}>
                    <EditUtilitiesPanel
                        home={home}
                        assessment={latestAssessment}
                    />
                </Modal>
            }
            <Modal title="Shares" opened={currentModal == 'share'} onClose={() => setCurrentModal(undefined)}>
                <SharePanel home={home}/>
            </Modal>
        </VStack>
    )
}