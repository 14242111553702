import {z, ZodType} from "zod";
import {extendZodWithOpenApi} from '@asteasolutions/zod-to-openapi';
import dayjs from "dayjs";
import {
    Assessment,
    GetAssessmentSchema,
    GetHomeSchema,
    GetQuotesSchema,
    GetRecommendationsSchema,
    GetSharesSchema,
    GetUserSchema
} from "./refined-tables.js";
import {
    ASSESSMENT_LABEL_BASELINE,
    AssessmentFile,
    AssessmentFileSchema,
    AssessmentFilesEnum,
    AssessmentFilesEnumSchema,
    EnergyDataRecord,
    HomeDetails,
    RecDataSchema,
    RecommendationFinancialsSchema
} from "./jsonb-schemas.js";
import {
    HubspotContact,
    HubspotCrmEntity,
    HubspotDeal,
    HubspotDealAggregate,
    HubspotMeeting,
    HubspotQuote
} from './hubspot.js';
import {UtilityApiAuthorization, UtilityApiBill, UtilityApiMeter} from './worker-schemas.js';
import {GetObjectCommand} from "@aws-sdk/client-s3";


export const KnownHomeDetailsKeys = {
    Beds: 'beds',
    Baths: 'baths',
    Sqft: 'sqft',
    Floors: 'floors',
    Cooling: 'cooling',
    Heating: 'heating',
} as const


export function validateArray<T>(array: Array<any>, zod: ZodType<T>, throwOnInvalidMessage?: string): Array<T> {
    const validatedData: Array<T> = []
    let invalidCount = 0
    for (const a of array) {
        const parseResult = zod.safeParse(a)
        if (!parseResult.success) {
            invalidCount++
            console.log(`validation error`)
            console.log(parseResult.error)
        } else {
            validatedData.push(parseResult.data)
        }
    }
    if (throwOnInvalidMessage && invalidCount > 0) {
        throw `${throwOnInvalidMessage}: ${invalidCount} of ${array.length}  invalid`
    }
    return validatedData
}

extendZodWithOpenApi(z);

export type RRecommendationData = z.infer<typeof RecommendationFinancialsSchema>
export const RecInputSchema = z.object({
    original_rec_id: z.string(),
    title: z.string(),
    description: z.string().nullish(),
    category: z.string(),
    type: z.string(),
    rec_data: RecDataSchema,
})
export type RecInputData = z.infer<typeof RecInputSchema>
export const RecManualInputDataSchema = RecInputSchema.merge(z.object({
    category: z.string().optional(),
    type: z.string().optional(),
}))

export type RecManualInputData = z.infer<typeof RecManualInputDataSchema>


export type LocationAssessment = {
    locationName: string
    infiltration: number
    insulation: number
    electrification: number
}


export const AwairDataSchema = z.record(z.string(), z.array(z.any()))

export const UserSchema = z.object({
    email: z.string(),
    id: z.string(),
    name: z.string().optional().nullable(),
    image: z.string().optional().nullable(),
    role: z.string().optional().nullable(),
})

export type User = z.infer<typeof UserSchema>

export const RentcastPropertiesSchema = z.object({
    // formattedAddress:z.string(),
    // addressLine1:z.string(),
    // addressLine2:z.string().nullish(),
    // city:z.string(),
    // state:z.string(),
    // zipCode:z.string(),
    // county:z.string(),
    // latitude:z.number(),
    // longitude:z.string(),
    bedrooms: z.number().optional(),
    bathrooms: z.number().optional(),
    yearBuilt: z.number().optional(),
    squareFootage: z.number().optional(),
    features: z.object({
        fireplace: z.boolean().optional(),
        floorCount: z.number().optional(),
        // heating:z.boolean(),
        // cooling:z.boolean(),
        // roofType:z.string(),
        // coolingType:z.string(),
        // heatingType:z.string(),
    }).optional()
})
export type RentcastProperties = z.infer<typeof RentcastPropertiesSchema>


export const RecommendationTemplateSchema = z.object({
    id: z.string(),
    title: z.string(),
    icon: z.string().nullish(),
    deal: z.enum(["No Deal", "Deal"]),
    type: z.enum(["DIY", "PRO"]),
    healthCategory: z.number().max(3),
    climateCategory: z.number().max(3),
    priceCategory: z.number().max(3),
    category: z.enum(["Electrification", "Insulation", "Infiltration"]),
    description: z.string().nullish(),
    healthSummary: z.string().nullish(),
    climateSummary: z.string().nullish(),
    priceSummary: z.string().nullish(),
    healthDescription: z.string().nullish(),
    climateDescription: z.string().nullish(),
    priceDescription: z.string().nullish(),
    prerequisites: z.array(z.string()),
    projectButtonText: z.string().nullish(),
    projectImageUrl: z.string().nullish(),
    projectUrl: z.string().nullish()
})
export type RecommendationTemplate = z.infer<typeof RecommendationTemplateSchema>
export const FileUploadNonceSchema = z.object({
    assessment_id: z.string(),
    assessmentFileEnum: AssessmentFilesEnumSchema,
    fileRecord: AssessmentFileSchema
})
export type FileUploadNonce = z.infer<typeof FileUploadNonceSchema>
export const FileDeleteNonceSchema = z.object({
    assessment_id: z.string(),
    assessmentFileEnum: AssessmentFilesEnumSchema,
    s3_url: z.string()
})
export type FileDeleteNonce = z.infer<typeof FileDeleteNonceSchema>

export const AuthzRolesSchema = z.enum(['site_admin', 'owner', 'write', 'read'])
export type AuthzRoles = z.infer<typeof AuthzRolesSchema>

export const AssessmentUpdateEventSchema = z.enum([
    "file-saved",
    "meeting-saved",
    "meeting-removed",
    "note-saved",
    "home-details-updated",
    "energy-data-saved",
    "assessment-data-updated",
    "solar-data-saved",
    "rec-added",
    "rec-deleted",
    "rec-hidden",
    "rec-updated",
    "status-changed"
])
export type AssessmentUpdateEvent = z.infer<typeof AssessmentUpdateEventSchema>

export const ExternalSyncRecordSchema = z.object({
    event: AssessmentUpdateEventSchema,
    assessment_id: z.string(),
})
export type ExternalSyncRecord = z.infer<typeof ExternalSyncRecordSchema>


export function getLatestAssessment(assessments: Array<Assessment>): Assessment | undefined {
    let latest
    for (const assessment of assessments) {
        if (!latest || dayjs(assessment.created_at).isAfter(latest.created_at)) {
            latest = assessment
        }
    }
    return latest
}

extendZodWithOpenApi(z);


export const RecommendationAggregateSchema = GetRecommendationsSchema
    .omit({rec_data: true})
    .merge(z.object({rec_data: RecDataSchema}))
    .merge(z.object({
        quotes: z.array(GetQuotesSchema.openapi("quote")),
        selectedQuote: GetQuotesSchema.nullish().openapi("quote")
    }))
    .openapi("recommendation")
export type RecommendationAggregate = z.infer<typeof RecommendationAggregateSchema>
export const HomeAggregateSchema = GetHomeSchema.merge(z.object({
    shares: z.array(GetSharesSchema.openapi("share")),
    assessments: z.array(GetAssessmentSchema.openapi("assessment")),
    /* for the life of me i can't figure out why we need to omit, then re-merge `rec_data` if we don't to this it shows up as a ZodLazy (with incorrect fields) to our code generator*/
    recommendations: z.array(RecommendationAggregateSchema),
    quotes: z.array(GetQuotesSchema.openapi("quote")),
    permissions: z.set(AuthzRolesSchema).openapi("permissions", {type: "object"})
})).openapi("home")
export type HomeAggregate = z.infer<typeof HomeAggregateSchema>

export const UserAggregateSchema = GetUserSchema.merge(z.object({
    homes: z.array(HomeAggregateSchema)
}))
export type UserAggregate = z.infer<typeof UserAggregateSchema>

export const AssessmentThresholdsSchema = z.record(
    z.string(),
    z.array(z.object({color: z.string(), simpleCondition: z.string()}))
)
export type AssessmentThresholds = z.infer<typeof AssessmentThresholdsSchema>
export const HomeDetailsFieldInfoSchema = z.object({
    type: z.string(),
    id: z.string(),
    title: z.string(),
    default: z.string().optional(),
    options: z.array(z.string())
})
export type HomeDetailsFieldInfo = z.infer<typeof HomeDetailsFieldInfoSchema>


const primaryGasHeaters: Array<HomeDetails["primary_heating"]> = ['Central gas furnace', 'Gas boiler']
const secondaryGasHeaters: Array<HomeDetails["secondary_heating"]> = ['Central gas furnace', 'Gas boiler', 'Room (through-the-wall) gas furnace']

export function requiresGasInput(details: HomeDetails): boolean {
    if (!details.stove || !details.primary_heating) {
        return true //we don't know yet, they need to fill in more details
    }
    if (details.stove == 'Gas') {
        return true
    }
    if (primaryGasHeaters.includes(details.primary_heating)) {
        return true
    }
    if (secondaryGasHeaters.includes(details.secondary_heating)) {
        return true
    }
    return false
}

function assessmentFileIsMissing(file: Array<AssessmentFile> | null | undefined): boolean {
    if (!file) {
        return true
    }
    return file.length == 0
}

function isEnergyUsageEmpty(usage: Record<string, EnergyDataRecord> | null | undefined): boolean {
    if (!usage) {
        return true
    }
    return Object.keys(usage).length == 0
}

export type ValidationResponse = { valid: true } | { valid: false, errors: Array<string> }

export function validateHomeownerInputs(assessment: Assessment): ValidationResponse {
    const errors: Array<string> = []
    if (assessmentFileIsMissing(assessment.assessment_files?.capture_video)) {
        errors.push("Capture Video Not Uploaded")
    }
    if (assessmentFileIsMissing(assessment.assessment_files?.window_image)) {
        errors.push("Window Image Not Uploaded")
    }
    if (!assessment.home_details?.stove) {
        errors.push("Home Details - `Stove` is missing")
    }
    if (!assessment.home_details?.primary_heating) {
        errors.push("Home Details - `Primary Heating` is missing")
    }
    if (isEnergyUsageEmpty(assessment.electric_usage)) {
        errors.push("Electric Usage Missing")
    }
    if (requiresGasInput(assessment.home_details || {})) {
        if (isEnergyUsageEmpty(assessment.gas_usage)) {
            errors.push("Gas Usage Missing")
        }
    }
    if (errors.length == 0) {
        return {valid: true}
    } else {
        return {valid: false, errors}
    }
}

export const AddressComponentTypeSchema = z.enum(["street_number", "route", "neighborhood", "locality", "administrative_area_level_2", "administrative_area_level_1", "country", "postal_code", "political"])
export type AddressComponentType = z.infer<typeof AddressComponentTypeSchema>
export const PlacesAddressSchema = z.object({
    formattedAddress: z.string(),
    location: z.object({
        latitude: z.number(),
        longitude: z.number(),
    }),
    addressComponents: z.array(z.object({
        shortText: z.string(),
        types: z.array(z.string()) //this should be AddressComponentTypeSchema, but we don't want to fail if we get unexpected options
    }))
})
export type PlacesAddress = z.infer<typeof PlacesAddressSchema>
export const NormalizedAddressSchema = z.object({
    normalizedComparable: z.string()
}).merge(PlacesAddressSchema)
export type NormalizedAddress = z.infer<typeof NormalizedAddressSchema>

export interface IKVApiClient {
    get<V>(key: string): Promise<V | 'not_found'>;

    put<V>(key: string, value: V, expiration_ttl: number): Promise<void>;
}

export const ConversionInfoSchema = z.object({
    urn: z.string(),
    objectExists: z.boolean(),
    conversionStatus: z.enum(['success', 'pending', 'inprogress', 'failed', 'timeout', 'not_found']),
    conversionProgress: z.string(),
})
export type ConversionInfo = z.infer<typeof ConversionInfoSchema>

export interface IAutodeskClient {
    getConversionInfo(file: { s3_url: string }): Promise<ConversionInfo>;

    idempotentCreateDerivative(file: { s3_url: string }): Promise<void>;

    getReadOnlyToken(): Promise<string>;

    getDerivativeDownloadBuffer(file: { s3_url: string }): Promise<Buffer>;

    getDerivativeThumbnailBuffer(file: { s3_url: string }): Promise<Buffer>;

    getAssessmentFileForEncodedUrn(urn: string): { s3_url: string };

    getUrnForAssessmentFile(file: { s3_url: string }): string;

}

export type AssociationsToSet = Array<{ id: string, entity: HubspotCrmEntity }>

export interface IHubspotCrmClient {
    updateHubspotDeal(hubspot_deal_id: string, propertiesToSet: Partial<HubspotDeal['properties']>): Promise<void>;

    updateHubspotContact(hubspot_contact_id: string, propertiesToSet: Partial<HubspotContact['properties']>): Promise<void>;

    createHubspotDeal(propertiesToSet: Partial<HubspotDeal['properties']>, associationsToSet: AssociationsToSet): Promise<string>;

    getHubspotContactById(hubspot_contact_id: string): Promise<HubspotContact>;

    getHubspotContactTypeById(hubspot_contact_id: string): Promise<string>;

    getHubspotDealById(hubspot_deal_id: string): Promise<HubspotDealAggregate | 'not_found'>;

    getHubspotQuoteById(hubspot_quote_id: string): Promise<HubspotQuote>;

    getHubspotDealForLineItemId(hubspot_line_item_id: string): Promise<HubspotDealAggregate | 'not_found'>;

    getHubspotDealForQuoteId(hubspot_quote_id: string): Promise<HubspotDeal | 'not_found'>;

    getHubspotQuoteForLineItemId(hubspot_line_item_id: string): Promise<HubspotQuote | 'not_found'>;

    getAssessmentDealForContactId(hubspot_contact_id: string): Promise<HubspotDeal>;

    getHubspotContactForDeal(hubspot_deal_id: string): Promise<HubspotContact | 'not_found'>;

    getHubspotDealsForContact(hubspot_contact_id: string): Promise<Array<HubspotDeal>>;

    deleteHubspotMeeting(hubspot_meeting_id: string): Promise<void>;

    deleteHubspotQuote(hubspot_quote_id: string): Promise<void>;

    deleteHubspotDeal(hubspot_deal_id: string): Promise<void>;

    getHubspotMeetingById(hubspot_meeting_id: string): Promise<HubspotMeeting | 'not_found'>;

    getHubspotContactForMeeting(hubspot_meeting_id: string): Promise<HubspotContact | 'not_found'>;
}

export interface IUtilityApiClient {
    listMeters(authorization_uid: string, next?: string): Promise<Array<UtilityApiMeter>>;

    listBills(meter_uids: Array<string>, next?: string): Promise<Array<UtilityApiBill>>;

    getAuthorization(authorization_uid: string): Promise<UtilityApiAuthorization>;

    triggerHistoricalCollection(meter_uids: Array<string>): Promise<void>;
}

export function getAssessmentS3GetObjectCommand(s3Url: string, cxt: {
    env: { S3_BUCKET_ASSESSMENT_FILES: string }
}): GetObjectCommand {
    const Bucket = cxt.env.S3_BUCKET_ASSESSMENT_FILES
    const regex = /^s3:\/\/[^/]*\/(.*)$/
    const match = s3Url.match(regex)
    if (!match || !match[1]) {
        throw `Malformed s3 url: ${s3Url}`
    }
    const Key = match[1]
    return new GetObjectCommand({
        Bucket,
        Key
    })
}

export function getLatestFinishedAssessment(home: HomeAggregate): Assessment | 'not_found' {
    const finishedAssessments = home.assessments.filter(a => a.assessment_status == 'done' || a.assessment_status == 'pending_homeowner_review')
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(a => a.assessment_label != ASSESSMENT_LABEL_BASELINE)
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return 'not_found'
}

export function getFileVersionsSortedByLatest(files: Array<AssessmentFile>): Array<AssessmentFile> {
    return [...files].sort((a, b) =>
        dayjs(a.created_date).unix() - dayjs(b.created_date).unix())
        .reverse()
}

export function getLatestFile(assessment: Assessment, file: AssessmentFilesEnum): AssessmentFile | 'not_found' {
    const files = (assessment.assessment_files ?? {})[file] ?? []
    return getFileVersionsSortedByLatest(files)[0] ?? 'not_found'
}

export type Sheet = Array<Array<string>>

export function getS3BucketHttpUrl(Bucket: string, region: string) {
    return `https://${Bucket}.s3.${region}.amazonaws.com`
}
export function getS3ObjectHttpUrl(Bucket: string, region: string,Key:string) {
    return `${getS3BucketHttpUrl(Bucket, region)}/${encodeURIComponent(Key)}`
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf('.')
    if (dotIndex < 0) {
        return ''
    } else {
        return fileName.slice(dotIndex + 1)
    }
}

export const Dynamic3dViewerConfigSchema = z.object({
    buildingNodeNames:z.array(z.string()),
    hideToolbar:z.boolean()
})
export type Dynamic3dViewerConfig = z.infer<typeof Dynamic3dViewerConfigSchema>