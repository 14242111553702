import {
    AbsoluteCenterStack,
    Badge,
    DesignedButton,
    EnergyChart,
    formatMoney,
    formatNumber,
    formatYears,
    HDivider,
    HStack,
    IconHeartChecked,
    IconHeartUnchecked,
    IconLeafChecked,
    IconLeafUnchecked,
    IconPriceTagChecked,
    IconPriceTagUnchecked,
    InfoTooltip,
    ProjectIcon,
    RHideStack,
    SheetsDataContext,
    Text2Xl,
    Text3Xl,
    TextBase,
    TextError,
    TextLg,
    TextLink,
    TextR,
    TextSm,
    TextSmItalic,
    Viewer,
    VStack
} from "@seeair/shared-components";
import React, {PropsWithChildren, ReactElement, useContext, useEffect, useState} from "react";
import {
    AssessmentFile,
    getLatestFile,
    getLatestFinishedAssessment,
    HomeAggregate,
    Quote,
    RecData,
    Recommendation
} from "@seeair/schemas";
import {Accordion, AccordionControl, AccordionPanel, CloseButton, Loader, Modal, SegmentedControl} from "@mantine/core";
import classNames from "classnames";
import {IconChevronDown} from "@tabler/icons-react";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";


export function RecommendationsPage({home}: { home: HomeAggregate }) {
    // const {data:threeDThumbnailSrc,mutate:load3dThumb} = trpc.HOMEOWNER.getAssessmentFileDownloadUrl.useMutation()
    const latestAssessment = getLatestFinishedAssessment(home)
    let electrification, latestRenderingFile
    if (latestAssessment != 'not_found') {
        latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
        // console.log(`lates file: ${JSON.stringify(latestRenderingFile)}`)
        electrification = latestAssessment.assessment_data?.electrification
    }
    // useEffect(() => {
    //     if (latestAssessment != 'not_found') {
    //         load3dThumb({home_id:home.home_id,assessment_id:latestAssessment.assessment_id,file_enum:'capture_rendering_enhanced.thumbnail'})
    //     }
    // }, [latestAssessment,load3dThumb,home]);
    return <VStack classNames="flex-grow bg-white">
        <VStack center>
            <Text3Xl light center>Heating & Cooling Energy Costs</Text3Xl>
            {
                electrification
                    ? <React.Fragment>
                        <HStack baseline center>
                            <TextLg classNames="pr-4">Annual Energy Usage:</TextLg>
                            <TextBase light>
                                {formatNumber(electrification.energy_use.Electric)} kWh
                            </TextBase>
                            <TextLg classNames="pr-4 pl-8">
                                Annual Gas Usage:&nbsp;
                            </TextLg>
                            <TextBase light>
                                {formatNumber(electrification.energy_use.Gas)}M BTU
                            </TextBase>
                        </HStack>
                        <EnergyChart
                            mobile
                            width={850}
                            height={350}
                            energy_chart={electrification.energy_chart}
                        />
                    </React.Fragment>
                    : <TextBase>Electrification Data Missing</TextBase>
            }
        </VStack>
        <HDivider/>
        <VStack center>
            <Text3Xl light center>Digital Twin</Text3Xl>

            {
                latestRenderingFile && latestRenderingFile != 'not_found'
                    ? <DigitalTwinSection file={latestRenderingFile} home={home}/>
                    : <DigitalTwinNotReady/>
            }
        </VStack>

        <HDivider/>
        <Text3Xl light center>Home Improvement Plan</Text3Xl>
        <VStack center>
            <VStack classNames="max-w-2xl">
                {
                    home.recommendations.length == 0
                        ? <TextBase>Your home is healthy and energy efficient, thanks for doing your part to save the
                            world!</TextBase>
                        : <RecommendationsAccordion recs={home.recommendations} quotes={home.quotes} filteredRecs={[]}/>
                }
            </VStack>
        </VStack>
    </VStack>
}

function DigitalTwinNotReady() {
    return <TextBase>Model Not Yet Ready</TextBase>
}

function DigitalTwinSection({file, home}: { file: AssessmentFile, home: HomeAggregate }) {
    const [showViewer, setShowViewer] = useState(false)
    const [selectedRecs, setSelectedRecs] = useState([] as Array<number>)
    const {
        data: urnResponse,
        error: urnError,
        isPending: urnPending
    } = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({file})
    if (urnPending) {
        return <VStack relative><AbsoluteCenterStack><Loader/></AbsoluteCenterStack></VStack>
    } else if (urnError) {
        return <VStack relative><AbsoluteCenterStack><TextError>Error Loading Digital
            Twin: {urnError.message}</TextError></AbsoluteCenterStack></VStack>

    } else if (!urnResponse || !urnResponse.urn || !urnResponse.objectExists || urnResponse.conversionStatus != 'success') {
        return <DigitalTwinNotReady/>
    }
    return <VStack center>
        <Modal classNames={{body: "p-0"}} withCloseButton={false} fullScreen opened={showViewer}
               onClose={() => setShowViewer(false)}>
            <VStack>
                <CloseButton size="xl" className="absolute top-2 right-2 z-50" onClick={() => setShowViewer(false)}/>
                <RHideStack xl reverse>
                    <Viewer
                        urn={urnResponse!.urn}
                        onRecToggleChange={(selectedRecNums) => {
                            setSelectedRecs(selectedRecNums)
                        }}
                        className="h-screen w-screen xl:w-2/3vw"/> {/*this should always be visible*/}
                    <VStack classNames="max-w-1/3 min-w-1/3 h-screen bg-slate-200 w-1/3vw max-h-screen overflow-auto"
                            style={{width: "33.333333vw"}}>
                        <RecommendationsAccordion recs={home.recommendations} quotes={home.quotes}
                                                  filteredRecs={selectedRecs}/> {/*this should be hidden unless on xl screen*/}
                    </VStack>

                </RHideStack>
            </VStack>
        </Modal>
        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <DesignedButton onClick={() => setShowViewer(true)}>Launch 3D Viewer</DesignedButton>
    </VStack>
}

function recToNum(r: Recommendation | undefined): number {
    if (!r || !r.original_rec_id) {
        return 0
    }
    return parseInt(r.original_rec_id) + (r.status == 'in_progress' ? -200 : r.status == 'done' ? 200 : 0)
}

function recStatusToString(r: Recommendation): string {
    switch (r.status) {
        case 'done':
            return "Done"
        case 'in_progress':
            return "In Progress"
        case 'not_started':
            return "Not Started"
    }
}

export function RecommendationsAccordion({recs, quotes, filteredRecs}: {
    recs: Array<Recommendation>,
    quotes: Array<Quote>,
    filteredRecs: Array<number>
}) {
    const sortedRecs = [...recs]
        .filter(r => filteredRecs.length == 0 || filteredRecs.includes(parseInt(r.original_rec_id ?? "0")))
        .sort((a, b) => recToNum(a) - recToNum(b))
    const [selected, setSelected] = useState(sortedRecs[0]?.original_rec_id ?? null)
    useEffect(() => {
        setSelected(sortedRecs[0]?.original_rec_id ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRecs]);
    return <VStack classNames="my-4"><Accordion maw={"100%"} variant="separated" radius="xl" value={selected}
                                                onChange={setSelected}>
        {sortedRecs
            .map(r => {
                let quote: Quote | undefined = undefined
                if (r.selected_quote_id) {
                    quote = quotes.find(q => q.quote_id == r.selected_quote_id)
                }
                return <Rec rec={r}
                            key={r.recommendation_id}
                            quote={quote}
                            initiallyExpanded={false /*sortedRecs.length == 1*/}/>
            })}
    </Accordion>
        {sortedRecs.length != recs.length && <TextSmItalic center wFull
                                                           classNames="mt-8">{`${recs.length - sortedRecs.length} filtered results`}</TextSmItalic>}
    </VStack>
}

function Rec({rec, quote, initiallyExpanded}: {
    rec: Recommendation,
    quote: Quote | undefined | null,
    initiallyExpanded: boolean
}) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
    const [expanded, setExpanded] = useState(initiallyExpanded)
    const utils = trpc.useUtils()
    // console.log(`recdata (${rec.original_rec_id}): ${JSON.stringify(rec.rec_data)}`)
    const {
        isPending: isSetRecStatusPending,
        isError: isSetRecActiveError,
        error: setRecActiveError,
        mutate: setRecStatus
    } = trpc.HOMEOWNER.changeRecommendationStatus.useMutation({
        onSuccess: async (data, variables, context) => {
            await utils.HOMEOWNER.invalidate()
            if (variables.status == "in_progress" && rec.type == "PRO") {
                notifications.show({message: "Your SeeAir Energy Advisor will be in touch shortly to start this project"})
            } else {
                notifications.show({message: "Update Success"})
            }
        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <Accordion.Item value={rec.original_rec_id ?? ""} className={classNames("border-primary-light-blue mx-4", {
        // "bg-green-gradient": rec.status == 'in_progress',
        // 'bg-slate-400': rec.status == 'done'
    })}>
        <AccordionControl icon={<VStack>
            <ProjectIcon lg imgSrc={`/recommendation-icons/${rec.original_rec_id}.png`}/>
        </VStack>}>
            <HStack leftCenter>
                <VStack>

                    <Badge green={rec.status == 'in_progress'}
                           gray={rec.status == 'not_started'}
                    >{recStatusToString(rec)}</Badge>
                    {quote && <Badge blue classNames="mt-2"
                    >Quote Available</Badge>}

                </VStack>
                <TextR medium center block wFull classNames="pr-12">{rec.title}</TextR>
            </HStack>
        </AccordionControl>
        <AccordionPanel>
            <VStack classNames={classNames("border-t-primary-light-blue")}>
                <HStack center>
                    <SegmentedControl
                        className="my-4"
                        disabled={isSetRecStatusPending}
                        data={[
                            {label: "Not Started", value: "not_started"},
                            {label: "In Progress", value: "in_progress"},
                            {label: "Done", value: 'done'}]}
                        value={rec.status}
                        onChange={(v) => {
                            setRecStatus({
                                home_id: rec.home_id,
                                recommendation_id: rec.recommendation_id,
                                status: v as 'not_started' | 'done' | 'in_progress'
                            })
                        }}
                    />
                </HStack>

                <ImpactSummary checkedIcon={<IconHeartChecked/>} uncheckedIcon={<IconHeartUnchecked/>}
                               score={matchingRec?.healthCategory || 0}
                               summary={matchingRec?.healthSummary || ""} title={"Health Impact"}
                               description={matchingRec?.healthDescription || ""} expanded={expanded} category="health"
                               rec_data={null}/>
                <ImpactSummary checkedIcon={<IconLeafChecked/>} uncheckedIcon={<IconLeafUnchecked/>}
                               score={matchingRec?.climateCategory || 0}
                               summary={matchingRec?.climateSummary || ""} title={"Climate Impact"}
                               description={matchingRec?.climateDescription || ""} expanded={expanded}
                               category="climate" rec_data={null}/>
                <ImpactSummary checkedIcon={<IconPriceTagChecked/>} uncheckedIcon={<IconPriceTagUnchecked/>}
                               score={matchingRec?.priceCategory || 0}
                               summary={matchingRec?.priceSummary || ""} title={"Price Impact"}
                               description={matchingRec?.priceDescription || ""} expanded={expanded}
                               rec_data={rec.rec_data} category="price"/>
                {quote && <VStack center><DesignedButton onClick={() => {
                    window.open(quote.quote_url, "_blank")
                }}>View Quote</DesignedButton></VStack>}
                <a onClick={() => setExpanded(!expanded)} className="flex flex-row justify-center items-center">
                    <TextLink classNames="transition">{
                        expanded
                            ? "Hide"
                            : "More Info"
                    }
                    </TextLink><IconChevronDown
                    className={classNames("text-blue-400 transition transform", {"actually-rotate-180": expanded})}/></a>
            </VStack>
        </AccordionPanel>
    </Accordion.Item>
}

/*

    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
 */

function ProformaText({children, indent,url}: PropsWithChildren<{ indent?: boolean,url?:string }>) {
    if(url) {
        return <a href={url} target="_blank">
            <TextLink light m0 classNames={classNames("leading-8", {
                "ml-8": indent,
            })}>{children}</TextLink>
        </a>
    }
    return <TextBase light m0 classNames={classNames("leading-8", {
        "ml-8": indent,
    })}>{children}</TextBase>
}

function formatMoneyRange(low: number, high: number): string {
    if(low==high) {
        return formatMoney(low)
    }
    return `${formatMoney(low)} to ${formatMoney(high)}`
}
function formatYearsRange(low:number, high:number): string {
    if(low==high){
        return formatYears(low)
    }
    return `${formatYears(low)} to ${formatYears(high)}`

}

function Proforma({rec_data}: { rec_data: RecData }) {
    const incentiveLowNumber = rec_data.Upfront_Cost_Low - rec_data.Net_Cost_Low
    const incentiveHighNumber = rec_data.Upfront_Cost_High - rec_data.Net_Cost_High
    const annualSavingAvg = rec_data.Annual_Savings_High + rec_data.Annual_Savings_Low / 2
    const paybackLowNumber = rec_data.Net_Cost_Low / annualSavingAvg
    const paybackHighNumber = rec_data.Net_Cost_High / annualSavingAvg
    return <VStack classNames="px-8 sm:px-16 border-t-primary-light-blue">
        <Text2Xl medium center>Estimated Cost</Text2Xl>
        <HStack between>
            <ProformaText>Cost before Incentive</ProformaText>
            <ProformaText>{formatMoneyRange(rec_data.Upfront_Cost_Low, rec_data.Upfront_Cost_High)}</ProformaText>
        </HStack>
        {
            rec_data.incentives
                ? <VStack>
                    <ProformaText>Incentives</ProformaText>
                    {
                        rec_data.incentives.map(i => <HStack between>
                            <ProformaText indent url={i.url}>
                                {i.title}
                                {i.description && <TextSm>&nbsp;({i.description})</TextSm>}</ProformaText>
                            {!i.timeOfPurchase && <InfoTooltip center label="Rebate after purchase"/>}
                            <ProformaText indent>
                                {formatMoneyRange(i.amountLow, i.amountHigh)}
                            </ProformaText>
                        </HStack>)
                    }
                </VStack>
                : <HStack between>
                    <ProformaText>Incentive</ProformaText>
                    <ProformaText>{formatMoneyRange(incentiveLowNumber, incentiveHighNumber)}</ProformaText>
                        </HStack>
                    }

                        <HDivider/>
                        <HStack between>
                            <ProformaText>Net Cost after Incentive</ProformaText>
                            <ProformaText>{formatMoneyRange(rec_data.Net_Cost_Low, rec_data.Net_Cost_High)}</ProformaText>
                        </HStack>
                        <HStack between>
                            <ProformaText>Annual Energy Savings</ProformaText>
                            <ProformaText>{formatMoneyRange(rec_data.Annual_Savings_Low,rec_data.Annual_Savings_High)}</ProformaText>
                        </HStack>
                        <HDivider/>
                        {isFinite(paybackLowNumber) && isFinite(paybackHighNumber) && <HStack between>
                            <ProformaText>Payback Period</ProformaText>
                            <ProformaText>{formatYearsRange(paybackLowNumber,paybackHighNumber)}</ProformaText>
                        </HStack>}

                        <TextSmItalic classNames="mt-8">*All costs are estimates and subject to change prior to contract
                            sign
                            off</TextSmItalic>
                    </VStack>
                    }

                    function
                    ImpactSummary({checkedIcon, uncheckedIcon, score, summary, title, description, expanded, category, rec_data}: {
                    checkedIcon: ReactElement,
                    uncheckedIcon: ReactElement,
                    score: number,
                    summary: string,
                    title: string,
                    description: string,
                    expanded: boolean,
                    category: 'health' | 'price' | 'climate',
                    rec_data: RecData | null
                }) {
                    const n = Math.floor(score)
                    const sizedCheckedIcon = <div className="w-10 h-10">{checkedIcon}</div>
            const sizedUncheckedIcon = <div className="w-10 h-10 opacity-25">{uncheckedIcon}</div>
    return <div className={classNames("flex items-center", {"flex-row": !expanded, "flex-col": expanded})}>
        {
            expanded
                ? <React.Fragment>
                    {
                        // we will show the score and description if it is there, but if we have a proform and no desccriptoin, we'll skip score and description
                        (category != 'price' || (!rec_data || description.length > 0)) && <React.Fragment>
                            <div className={classNames("px-8 flex flex-row items-center justify-start w-full")}>
                                {[
                                    ...list(n).map(i => sizedCheckedIcon),
                                    ...(n < 3 ? list(3 - n).map(i => sizedUncheckedIcon) : []),
                                    <TextLg classNames="ml-8">{title}</TextLg>
                                ]}
                            </div>
                            <TextBase classNames={classNames("transition mx-8")}>
                                {description}
                            </TextBase>
                        </React.Fragment>
                    }
                    {rec_data && expanded && <Proforma rec_data={rec_data}/>}
                </React.Fragment>
                : <React.Fragment>
                    <div className={classNames("px-8 flex flex-row items-center justify-start")}>
                        {sizedCheckedIcon}
                    </div>
                    <TextLg classNames={classNames("transition mx-8")}>
                        {summary}
                    </TextLg>
                </React.Fragment>
        }
    </div>
}

function list(n: number): Array<number> {
    return Array.from(new Array(n).keys())
}