import React, {PropsWithChildren} from "react";
import {AssessmentThresholds, RecommendationTemplate} from "@seeair/schemas";
import {trpc} from "~/lib-client";

type SheetsData = {
    thresholdsSheet:AssessmentThresholds,
    recommendationsSheet:Array<RecommendationTemplate>
}
const DefaultSheetsData:SheetsData = {
    thresholdsSheet: {},
    recommendationsSheet:[]
}
export const SheetsDataContext = React.createContext<SheetsData>(DefaultSheetsData)
export function SheetsDataProvider({children}:PropsWithChildren){
    // console.log("sheets data provided outlet rendered")
    const utils = trpc.useUtils()
    const [{recommendationsSheet,thresholdsSheet}] = trpc.SHEETS.readAllSheets.useSuspenseQuery()
    return (
        // <HomesList homes={homes} />
        <SheetsDataContext.Provider value={{thresholdsSheet, recommendationsSheet}}>
            {children}
        </SheetsDataContext.Provider>
    )
}