import {Quote, RecommendationAggregate} from "@seeair/schemas";
import {SegmentedControl, Table} from "@mantine/core";
import React from "react";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";

export function QuotesTable({rec}: { rec: RecommendationAggregate }) {
    const rows: string[][] = (rec.quotes || []).reduce((acc, v) => [...acc, [
        v.quote_title || '',
        v.hubspot_quote_amount || '',
        v.quote_url || '']], [] as string[][])
    return <Table
            striped
            withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Title</Table.Th>
                <Table.Th>Amount</Table.Th>
                <Table.Th>URL</Table.Th>
                <Table.Th>Selected</Table.Th>
            </Table.Tr>
        </Table.Thead>
        {(rec.quotes ?? []).map(q=><QuotesRow rec={rec} quote={q}/>)}
        <Table.Caption>
            {`${rows.length} Quotes from Hubspot`}
        </Table.Caption>
    </Table>
}
export function QuotesRow({quote,rec}:{rec:RecommendationAggregate,quote:Quote}) {
    const utils = trpc.useUtils()
    const {
        isPending: isSelectQuotePending,
        isError: isSetRecActiveError,
        error: setRecActiveError,
        mutate: selectQuote
    } = trpc.ADMIN.selectQuote.useMutation({
        onSuccess: async () => {
            await utils.ADMIN.invalidate()
            notifications.show({message: `Success`})

        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <Table.Tr>
        <Table.Td>{quote.quote_title}</Table.Td>
        <Table.Td>{quote.hubspot_quote_amount}</Table.Td>
        <Table.Td><a target="_blank" href={quote.quote_url}>{quote.quote_url}</a></Table.Td>
        <Table.Td><SegmentedControl
            className="my-4"
            disabled={isSelectQuotePending}
            data={[
                {label: "Selected", value: "selected"},
                {label: "Not Selected", value: "not"}]}
            value = {rec.status}
            onChange={(v) => {
                selectQuote({
                    home_id: rec.home_id,
                    recommendation_id: rec.recommendation_id,
                    quote_id: quote.quote_id,
                    selected: v=="selected"
                })
            }}
        /></Table.Td>
    </Table.Tr>

}